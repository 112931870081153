.liveStreamSettingPopOver {
  position: absolute;
  left: 0%;
  bottom: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  background-color: #075fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transform: translateX(-200%);
  z-index: 100000;
  &.isOpen {
    background-color: #0042bb;
  }
}
