.wrapper {
  height: 100%;
}

.localVideo {
  position: relative;
  width: 100%;
  height: 20%;

  &.localVideoHide div video {
    display: none;
  }
  &.localVideoBG{
    background-color:black;
  }
  .fullVideoBtnHide {
    display: none;
  }

  button {
    width: 25%;
    height: 25px;
  }
  .offFullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &.offFullVideoBtnHide {
      display: none;
    }
    img {
      position: absolute;
      width: 50%;
      top: 30%;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
  .fullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 10%;
    margin: 10px;
    right: 0px;
    top: 0px;
    img {
      position: absolute;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
}

.remoteVideoContainer {
  width: 50%;
  max-height: 100%;
  position: absolute;
  left: -50%;
  
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .chatRoomWrapper {
    height: 50%;
  }
  .teacherVideo {
    width: 100%;
  }
  .controls {
    height: 10%;
    .studentListbutton {
      width: 210px;
      height: 40px;
      left: 12px;
      top: 10px;
      background: #006db2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      text-align: center;
      margin: 0 auto;
      line-height: 40px;
    }
  }
}

.listContent {
  width: 250px;
  height: 50vh;
  overflow-y: hidden;

  .title {
    display: flex;
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* line-height: 140.62%; */
    letter-spacing: 0.05em;
    color: #3c4858;
    border-bottom: 1px solid #d0e0e2;
    padding-bottom: 5px;
    width: 100%;

    .titleText{
      width: 50%;
    }

    .totalUser{
      width: 50%;
      font-size: 16px;
      color: #6e6e6e;
      display: inline-grid;
      align-items: center;
      text-align: right;
    }
  }
  .controlButtons {
    width: 100%;
    display: flex;
  }
  .fieldName {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #d0e0e2;
    padding-top: 10px;
    padding-bottom: 5px;
    color: #004B62;
    .studentName {
      width: 80%;
    }
    .controls {
      width: 20%;
    }
  }
  .lists {
    padding-top: 10px;
    overflow-y: auto;
    height: 100%;

    .listItem {
      display: flex;
      border-bottom: 1px solid #d0e0e2;
      padding-bottom: 5px;

      .text {
        width: 80%;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .raiseHand {
        max-width: 15%;
        text-align: right;
        cursor: pointer;

        .button {
          min-width: 20%;
        }
      }
    }
  }
}

.closeDialog {
  width: 30%;
  padding: 5px 0;
  text-align: center;
  margin: 0 auto;
  background: #00828f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.listDialogBottom{
  width: 100%;
  display: flex;
  .bottomText{
    display: inline-grid;
    align-items: center;
    width: 70%;
  }
  .closeListDialog {
    width: 30%;
    padding: 5px 0;
    text-align: center;
    margin: 0 auto;
    background: #00828f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
}

