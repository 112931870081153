.localVideo {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: none;
  .volumeMeter{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}