form{
  display: flex;
  flex-direction:column;

  margin: 5px auto;
  max-width: 300px;
}

form > *{
  margin: 5px;
}

.roleOption{
  display:none;
}