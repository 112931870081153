.wrapper {
  height: 100%;
}

.localVideo {
  position: relative;
  width: 100%;
  height: 20%;
}

.teacherVideo {
  position: relative;
  width: 100%;
  height: 20%;
  margin: 0 auto;

  .localVideoHide div video {
    display: none;
  }
  &.localVideoBG{
    background-color:black;
  }

  .fullVideoMessage {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &.fullVideoMessageHide {
      display: none;
    }
    img {
      position: absolute;
      width: 50%;
      top: 30%;
    }
  }
}

.remoteVideoContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 10000;
}

.chatContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .chatRoomWrapper {
    height: 50%;
  }
}
