@import '../../../style/media';

.readerTools {
	position: relative;
	display: flex;
	justify-content: space-between;
	background-color: #51464C;
	width: 100%;
	height: 12vh;
	@media screen and (max-width: 1023px) , screen and (max-device-width: 1023px) {
		min-height: 18vh;
	}

	@media (-webkit-device-pixel-ratio: 1.5) {
		@media screen and (max-width: 1920px) {
			min-height: 14vh;
		}
	}

	@media (-webkit-device-pixel-ratio: 1.25) {
		@media screen and (max-width: 1920px) {
			min-height: 12vh;
		}
		@media screen and (max-width: 1440px) {
			min-height: 14vh;
		}

		@media screen and (max-width: 1100px) {
			min-height: 16.5vh;
		}
	}

	@media (-webkit-device-pixel-ratio: 1) {
		@media screen and (max-width: 1440px) {
			min-height: 14vh;
		}

		@media screen and (max-width: 1100px) {
			min-height: 12vh;
		}
		@media screen and (max-width: 1024px) {
			min-height: 14vh;
		}
		@media screen and (max-width: 800px) {
			min-height: 20vh;
		}
	}
	
	&.hide{
		pointer-events: none;
		-webkit-filter: grayscale(1);
	}

	&.top {
		border-bottom: 1px solid #ccc;
		flex-direction: row;
	}

	&.bottom {
		border-top: 1px solid #ccc;
		flex-direction: row;
	}

	&.left {
		width: 80px;
		height: 100%;
		flex-direction: column;

		.right {
			width: 100%;
			height: 20%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.middle {
			width: 100%;
			height: 70%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.left {
			width: 100%;
			height: 10%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.text {
			display: none;
		}
	}

	&.right {
		width: 80px;
		height: 100%;
		flex-direction: column;

		.right {
			width: 100%;
			height: 20%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.middle {
			width: 100%;
			height: 70%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;		
		}

		.left {
			width: 100%;
			height: 10%;
			flex-wrap: wrap;
			justify-content: center;
			overflow-y: auto;
		}

		.text {
			display: none;
		}
	}

	.left {
		// width: 10%;
		display: flex;
		align-items: center;
		background-color: #000;
	}

	.middle {
		// background-color: #51464C;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		// 	// width: 100%;
		// 	// max-width: 100%;
		// overflow-x: auto;
		// overflow-y: hidden;
		// &::-webkit-scrollbar{
		// 	height: 10px;
		// 	background-color: #725f5a;
		// }
		// &::-webkit-scrollbar-thumb {
		// 	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		// 	background-color: #cf9482;
		// }

	}

	.adminText{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		color: white;
		font-size: 50px;
	}

	.right {
		// width: 20%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: #000;
	}
	
	.left, .right {

		@media (-webkit-device-pixel-ratio: 1.5) {
			@media screen and (max-width: 1920px) {
				button {
						width: 9vh;
						min-width: 9vh;
					}
			}
			@media screen and (max-width: 1280px) {
				// width: 20%;
			}
		}

		@media (-webkit-device-pixel-ratio: 1.25) {
			@media screen and (max-width: 1920px) {
				button {
						width: 9vh;
						min-width: 9vh;
					}
			}
			@media screen and (max-width: 1440px)  {
				button {
					width: 11vh;
					min-width: 10vh;
				}
			}


			@media screen and (max-width: 1280px) {
				button {
					width: 8.5vmin;
					min-width: 8.5vmin;
				}
			}

			@media screen and (max-width: 1100px) {
				button {
					width: 8.5vmin;
					min-width: 8.5vmin;
				}
			}

			@media screen and (max-width: 1024px) {
				button {
					width: 6.5vmin;
					min-width: 6.5vmin;
				}
			}
		}


		@media (-webkit-device-pixel-ratio: 1) {
			@media screen and (max-width: 1440px)  {
				button {
					width: 8vh;
					min-width: 8vh;
				}
			}

			@media screen and (max-width: 1280px) {
				button {
					width: 6vh;
					min-width: 6vh;
				}
			}

			@media screen and (max-width: 1100px) {
				button {
					width: 7vh;
					min-width: 7vh;
				}
			}

			@media screen and (max-width: 1023px) {
				button {
					width: 12vh;
					min-width: 12vh;
				}
			}
		}
	}

	.text {
		width: 100%;
		font-size: .83vmax;
		@media screen and (max-width: 1280px) and (min-width: 1024px) {
			font-size: 12px
		}
	}

	.toolBtn {
		position: relative;
		min-width: auto;
	}

	.addBtn {
		width: 100%;
		height: 100%;
		color: #fff;
		position: relative;
		overflow: hidden;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 10px;
	}

	.addFileChange {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 100%;
		min-height: 100%;
		font-size: 100px;
		text-align: right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;
		background: white;
		cursor: inherit;
		display: none;
	}
}


.mobileReaderTools {
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px 1px #ccc;
	background-color: #fff;
}

.toolbox{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -10px;
	.tools{
		margin: 10px;
		button {
			font-size:.83vmax;
		}
	}
}


// 4K screen
// @media screen and (min-width: 3000px) {
// 	.readerTools {
// 		height: 300px;
// 	}
// }

// 1280 screen
@media screen and (max-width: 1280px) {
	.readerTools {
		height: 11vh;
	}
}